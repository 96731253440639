import React from "react"
import PropTypes from "prop-types"

class Sidebar extends React.Component {
  render () {
    return (
      <div className="sidebar">
        <div className="sidebar-box">
          <div className="row">
            <div className="col-md-12">
              <h5>관련된 토론</h5>
            </div>
          </div>
        </div>
        <div className="sidebar-box">
          <div className="row">
            <div className="col-md-12">
              <h5>statement_recommend</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  greeting: PropTypes.string
};
export default Sidebar
