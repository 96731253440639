// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import "../stylesheets/application"

import Api from '../src/api';
import Helper from '../src/helper';
import Crop from '../src/crop';
import Potter from '../src/potter.es6';
import Username from '../src/username.es6';
import t from '../src/selector.es6';


// https://stackoverflow.com/a/7647125/2566679
// document.querySelectorAll('[property]'); // All with attribute named "property"
// document.querySelectorAll('[property="value"]'); // All with "property" set to "value" exactly.

window.addEventListener('DOMContentLoaded', function () {
  console.log ("javascript_event__________ window.addEventListener('DOMContentLoaded'")

  var potter = new Potter();
  var formed_text = potter.form('# Jesus have mercy with me!');
  console.log (formed_text);


  /* Crop */
  if (t('#crop_temp_image_uploader')){
    Crop.init();

    t("#crop_temp_image_uploader").addEventListener("change", function(e){
      e.preventDefault();
      t("#crop_temp_image_upload_form").submit();
    }, false);
  }

  /* ...? TODO  */
  if (t('#user_username')){
    t("#user_username").addEventListener("keyup", Helper.debounce(Username.validate_username, 200), false);
  }


});





document.addEventListener('turbolinks:before-render', () => {
  // TODO add throttle function here
  // document.removeEventListener("scroll", throttle)
});


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
