import React from "react"
import PropTypes from "prop-types"
import Api from '../../../src/api'
import LivingText from "../LivingText"
import Action from "../Action"

class Article extends React.Component {
  constructor(props) {
    super(props);
    console.log ("modle/Article ", this.props)

    // const state = {
    //   id: '',
    //   // title:
    //   title_placeholder: '',
    //   context_placeholder: '',
    //   raw_reason_placeholder: ''
    // }

    // const { article } = this.props;
    this.state = {
      ...this.props.article,
      title_placeholder: '', //#article.title_placeholder,
      context_placeholder: '', //article.context_placeholder,
      raw_reason_placeholder: '', // article.ra
    }
    console.log ("modle/Article this.state 22", this.state)
    // d = {...a, ...{ id: 'd'}}

  }

  componentDidMount() {
    console.log("modle/article componentDidMount")
    if (!this.props.article.id){
      return;
    }

    Api.get({
      url: `/articles/${this.props.article.id}/edit.json`,
      onSuccess: (data) => {
        const article = data.data;
        console.log ("onSuccessonSuccess", article)
        this.setState({
          id: article.id,
          title: article.title,
          raw_content: article.raw_content,
          title_placeholder: '', //#article.title_placeholder,
          context_placeholder: '', //article.context_placeholder,
          raw_reason_placeholder: '', // article.raw_reason_placeholder,
        })
      }
    })
  }

  handleTitleChange = (event) =>  {
    this.setState({
      title: event.target.value
    })
  }

  handleRawChange = (event) =>  {
    this.setState({
      raw_content: event.target.value
    })
  }

  handleSubmit = (event) => {
    const article = {
      title: this.state.title,
      raw_content: this.state.raw_content
    }

    if (this.state.id){
      Api.patch({
        url: `/articles/${this.state.id}`,
        data: {
          article: {...article, ...{ id: this.state.id }}
        },
        onSuccess: this.createdOrUpdatedDone
      })

    }else{
      Api.post({
        url: '/articles',
        data: {
          article: article
        },
        onSuccess: this.createdOrUpdatedDone
      })
    }
  }

  createdOrUpdatedDone = (data) =>  {
    console.log ("createdOrUpdatedDone", data.data.url)
    window.location.replace(data.data.url);
  }

  render () {
    return (
      <React.Fragment>
        <h1>articles form</h1>
        <input
          type="text"
          name="article[title]"
          id="article_title"
          value={ this.state.title }
          onChange={ this.handleTitleChange }
          placeholder={ this.state.title_placeholder }
        />
        <LivingText
          raw={ this.state.raw_content }
          handleChange={ this.handleRawChange }
          placeholder={ this.state.raw_reason_placeholder }
        />
        <Action
          id={ this.state.raw_content }
          handleSubmit={ this.handleSubmit }
        />
      </React.Fragment>
    );
  }
}

Article.propTypes = {
  greeting: PropTypes.string
};
export default Article
