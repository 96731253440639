import { call, put, takeEvery } from 'redux-saga/effects';
import {
  loadingBar,
  setCellData
} from '../actions';

import axios from "axios";

const apiGet = (option) => {
  return axios({
    method: "get",
    cancelToken: option.token ? option.token.token : null,
    url: option.url,
    params: Object.assign({}, option.params),
    withCredentials: true
  })
}

function* fetchCellData(opts) {
  try {
    yield put(loadingBar({ index: opts.index, loading: true }))
    const res = yield call(apiGet, { url: opts.url });
    yield put(setCellData({ index: opts.index, data: res.data }))
    yield put(loadingBar({ index: opts.index, loading: false }))

  } catch (error) {
    yield put(setCellData({ index: opts.index, data: { error: { message: `Data loading failed due to: ${error}`  } }  }))
    yield put(loadingBar({ index: opts.index, loading: false }))
  }
}

export default function* cells() {
  yield takeEvery('FETCH_CELL_DATA', fetchCellData);
}
