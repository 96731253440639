import MarkdownIt from './markdown-it.js';
// import hljs from './highlight.js';   Couldn't do this with mini racer. see  https://www.pivotaltracker.com/story/show/176320679

// Then the Lord God formed the man of dust from the ground
// and breathed into his nostrils the breath of life,
// and the man became a living creature. - Genesis 2:7 (ESV)
//
// opts = {
//   dust: { abc: true },
//   breath_of_life: {
//     truth: { 1: 10, 2: 5, 3: 2 },  statement_id: truth
//     other: {}
//   }
// }
// const potter = new Potter(opts)
// formed = potter.form(raw)
// living = potter.breathe(formed)
class Potter {
  constructor(opts={}) {
    // see https://github.com/markdown-it/markdown-it
    this.engine = new MarkdownIt({
      html:         true,
      breaks:       true,
      langPrefix:   'language-',
      linkify:      true,
      typographer:  true


      // Couldn't do this with mini racer. see  https://www.pivotaltracker.com/story/show/176320679

      // highlight: function (str, lang) {
      //   if (lang && hljs.getLanguage(lang)) {
      //     try {
      //       return hljs.highlight(lang, str).value;
      //     } catch (__) {}
      //   }
      //
      //   return ''; // use external default escaping
      // }

    });
  }

  form(raw){
    return this.engine.render(raw);
  }

  breathe(formed){
    const living = formed
    return living;
  }
}

export default Potter
