// To reduce long method name
// t doesn't have any meaning. App name is Truth so t.
const t = (selector) => {
  if (selector.startsWith("#")){
    let id = selector.replace('#', '')
    return document.getElementById(id);
  }
}

export default t
