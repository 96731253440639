import axios from 'axios'

axios.interceptors.request.use(config => {
  // log a message before any HTTP request is sent
  console.log('Request_______interceptors');
  console.log (config)

  config.headers = {...config.headers, ...{
    'X-CSRF-Token': document.getElementsByName("csrf-token")[0].getAttribute('content')
  }}

  return config;
});

const Api = {
  get: (opts) => {
    Api.call({...opts, ...{
      method: 'GET',
      timeout: 5000,
      data: opts.data ? opts.data : {}
    }})
  },
  post: (opts) => {
    Api.call({...opts, ...{
      method: 'POST',
      timeout: 10000   // 10 seconds timeout
    }})
  },
  patch: (opts) => {
    Api.call({...opts, ...{
      method: 'PATCH',
      timeout: 10000   // 10 seconds timeout
    }})
  },
  call: (opts) => {
    console.log (`Api get called with opts: ${opts}`)

    const config = {
      url: opts.url,
      method: opts.method,
      timeout: opts.timeout,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      [opts.method === 'GET' ? 'params' : 'data']: opts.data
    };

    axios(config)
      .then(response => {
        console.log("response success", response.status);
        try{
          opts.onSuccess(response)
        }catch (error) {
          console.error("error on onSuccess");
          console.error(error);
        }
      })
      .catch(error => {
        console.error ("axios error_config")
        console.error(error.config);
          if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.error ("axios error.response")
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
          }
          else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
            // Node.js의 http.ClientRequest 인스턴스입니다.
            console.error ("axios error.request")
            console.error(error.request);
          }
          else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.error('Error', error.message);
          }


      });

    //
    //
    // axio()
    // return new Promise(function(resolve, reject) {
    //   $.ajax({
    //     url: url,
    //     data: data,
    //     success: function(data) {
    //       resolve(data);
    //     },
    //     error: function(err) {
    //       reject(err);
    //     }
    //   });
    // });
  }
  // call: (url, method, data = {}) => {
  //   return new Promise(function(resolve, reject) {
  //     $.ajax({
  //       url: url,
  //       type: method,
  //       data: data,
  //       beforeSend: function (xhr, options) {
  //         xhr.setRequestHeader( 'X-CSRF-Token', $( 'meta[name="csrf-token"]' ).attr( 'content' ));
  //       },
  //       success: function(data) {
  //         resolve(data);
  //       },
  //       error: function(err) {
  //         reject(err);
  //       }
  //     });
  //   });
  // }
}


export default Api;
