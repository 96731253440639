import React from "react"
import PropTypes from "prop-types"
import Autosuggest from 'react-autosuggest';  // https://github.com/moroshko/react-autosuggest

// Wrapper for Autosuggest with Truth Autosuggest rule.
class TruthAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: []
    }
    // this.state = {
    //   // statement_id: this.props.statement.id,
    //   judgement_id: this.props.judgement_id,
    //   judgement_truth: this.props.judgement_truth,
    //   premises: this.props.premises,
    //   title_placeholder: '',
    //   context_placeholder: '',
    //   raw_reason_placeholder: '',
    //   value: '',            // for suggestion
    //   suggestions: []       // for suggestion
    // }
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    // reason:  string describing why onSuggestionsFetchRequested was called. The possible values are:
        // 'input-changed' - user typed something
        // 'input-focused' - input was focused
        // 'escape-pressed' - user pressed Escape to clear the input (and suggestions are shown for empty input)
        // 'suggestions-revealed' - user pressed Up or Down to reveal suggestions
        // 'suggestion-selected' - user selected a suggestion when alwaysRenderSuggestions={true}
      console.log ("value", value)
    return this.props.getSuggestions({
              value: value,
              reason: reason,
              setSuggestions: (suggestions) => {
                this.setState({
                  suggestions: suggestions
                })
              }
            })
  };

  onChange = (e, { newValue, method }) => {
    e.preventDefault();
    this.setState({
      value: newValue
    });
  };

  onKeyPress = (e) => {
    // e.preventDefault();   // Don't do this. It breaks Autosuggest event.
    if(e.key === 'Enter'){
      console.log ("enter_________________________")
      e.preventDefault();
      this.props.handleOnEnterKeyPress(e)
      this.onSuggestionsClearRequested()
    }
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.name;
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return this.highlightRestOfGiven(query, suggestion.name)
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      value: ''  // empty the input
    });
  };

  highlightRestOfGiven = (query, keyword) => {
    const q = this.sanitized(query)
    if (!q){
      return <div>{ keyword }</div>
    }

    // q = `^${q}` // replace only prefix
    const match = new RegExp(`^${q}`,"i").exec(keyword)
    // const match = new RegExp(q,"i").exec(keyword)
    if(!match){
      return <div>{ keyword }</div>
    }

    return <div dangerouslySetInnerHTML={{ __html: `${match[0]}<b>${keyword.replace(new RegExp(q,"i"), '')}</b>` }} />
  }

  sanitized = (q) => {
    return q.replace('(', '\(')
            .replace(')', '\)')
            .replace('*', '\*')
            .replace('+', '\\\+')
            .replace('?', '\?')
  }

  render () {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    };

    return (
      <React.Fragment>
        {/*
          I can customize input like below. see https://github.com/moroshko/react-autosuggest/issues/437#issuecomment-330025442
          const renderInputComponent = inputProps => (
              <div>
                  <Input style={{width: '350px'}} icon='search' {...inputProps} />
              </div>
          );
        */}
        <Autosuggest
          suggestions={ suggestions }
          onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
          onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
          onSuggestionSelected={ this.props.onSuggestionSelected }
          getSuggestionValue={ this.getSuggestionValue }
          renderSuggestion={ this.renderSuggestion }
          inputProps={inputProps}
        />
      </React.Fragment>
    );
  }
}

TruthAutosuggest.propTypes = {
  greeting: PropTypes.string
};
export default TruthAutosuggest
