import Cropper from 'cropperjs';
// import 'cropperjs/dist/cropper.css';

const Crop = {
  init: () => {
    const image = document.getElementById('crop_temp_image');
    const preview = document.getElementById('crop_preview');
    if (!image){
      return;
    }

    const cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 2,
      minContainerHeight: 300,
      ready: function () {
        set_crop_form(cropper.getData())
      },
      preview: preview,
      cropend: function (event) {
        set_crop_form(cropper.getData())
      },
    });

    function set_crop_form(d){
      document.getElementById('crop_x').value = d.x;
      document.getElementById('crop_y').value = d.y;
      document.getElementById('crop_w').value = d.width;
      document.getElementById('crop_h').value = d.height;
    }

  }
}

export default Crop;
