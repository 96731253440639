export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_SELECTED_PERIOD':
      return {
        ...state,
        options: {
          ...state.options,
          period: {
            ...state.options.period,
            selected: action.period
          }
        }
      }

    default:
      return state;
  }
}
