import t from '../src/selector.es6';
import Api from '../src/api';

const Username = {
  validate_username: () => {
    const username = t("#user_username").value;
    let username_box = t("#username_box")
    let msg_box = t("#username_validation_result_messsage")

    if (!username.trim()){
      username_box.innerText = '@';
      msg_box.innerText = ' ';
      Username.invalid(msg_box);
      // msg_box.classList.add('invalid');
      return;
    }

    Api.get({
      url: `/my/validate_username`,
      data: { username: username },
      onSuccess: (result) => {
        console.log ("result11", result)
        username_box.innerText = `@${username}`;
        msg_box.innerText = result.data.message;
        // this.msg_box.style.display = 'block'

        if (result.data.valid){
          Username.valid(msg_box);
        }else{
          Username.invalid(msg_box);
        }
      }
    })
  },
  valid: (msg_box) => {
    msg_box.className = ''
    msg_box.classList.add('valid');
    t("#username_form_submit").removeAttribute("disabled")
  },
  invalid: (msg_box) => {
    msg_box.className = ''
    msg_box.classList.add('invalid');
    t("#username_form_submit").setAttribute("disabled", "disabled")
  }
}

export default Username
