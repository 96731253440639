import React from "react"
import { connect } from 'react-redux';
import PropTypes from "prop-types"
import Api from '../../src/api'
import TruthEditor from "../editor/TruthEditor"

class Article extends React.Component {
  constructor(props) {
    super(props);
    console.log ("view/Post", this.props)
    console.log ("view/Post.id id", this.props.id)
    this.state = {
      article_form: (this.props.id ? false : true),
    }
  }

  editPost = () => {
    this.setState({
      article_form: !this.state.article_form
    })
  }

  render () {

    return (
      <div className="article">
        <div className="title">
          <h1>{this.props.title}</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-12">
                <div className="reason">
                  <div
                    className="living-text"
                    dangerouslySetInnerHTML={{ __html: this.props.formed_content }}
                  >
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="meta_area">
                <span className="info-sm">
                  created_by:
                  <a href="my_account_path" target="_blank">
                    <img
                      src={ this.props.writer_avatar_tiny }
                      className='rounded-circle avatar-tiny'
                      />
                      &nbsp; { this.props.writer_name }
                  </a>
                </span> &nbsp;&nbsp;
                {
                  this.props.editable
                  ?
                  <span className="info-sm">
                    <a
                      onClick={ this.editPost }
                    >
                      수정하기
                    </a>
                  </span>
                  :
                  null
                }
                <br/>
                <span className="info-sm">created: { article.created_at } </span> &nbsp;&nbsp;
                <span className="info-sm">modified: { article.updated_at } </span>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.article_form
          ?
          <div className="truth-editor-container-in-react">
            <TruthEditor
              model='Article'
              hangu_edit='hangu_edit'
              article={ this.props }
            />
          </div>
          :
          null
        }
      </div>
    );
  }
}

Article.propTypes = {
  greeting: PropTypes.string
};

// import Header from '../components/Header';
// import Body from '../components/Body';
// import { setUrlParams } from '../lib/url_helper';
// import {
//   setSelectedPeriod,
//   setCells,
//   refreshAllCells
// } from '../actions';



const mapStateToProps = (
  state,
  ownProps
) => {
  return {
    ...state.article
  }
}

const mapDispatchToProps = (
  dispatch,
  ownProps
) => {
  return {
    // setPeriod: (opts) => {
    //   dispatch(setSelectedPeriod(opts))
    // },
    // setCells: (opts) => {
    //   dispatch(setCells(opts))
    // },
    // refreshAllCells: () => {
    //   dispatch(refreshAllCells())
    // }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Article)
