const Helper = {
  debounce: (func, wait, immediate) => {
    var timeout;
    console.log("change event..... ......... 22")
    var context = this, args = arguments;
      console.log("context", context);
    console.log("args", args);

    return function() {
      var context = this, args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      }, wait);
      if (immediate && !timeout) func.apply(context, args);
    };
  }
}

export default Helper;
