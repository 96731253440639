import React from "react"
import PropTypes from "prop-types"
import Potter from "../../src/potter.es6"

class LivingText extends React.Component {
  constructor(props) {
    super(props);
    console.log("LivingText p", this.props)
    // opts = {
    //   dust: { abc: true },
    //   breath_of_life: {
    //     truth: { 1: 10, 2: 5, 3: 2 },
    //     other: {}
    //   }
    // }
    // new Potter(opts)

    this.state = {
      raw: '',
      placeholder: '',
      formed: '',
      potter: new Potter()
    }


    // console.log("constructor p", this.props)

    // SSR: When we use `console.log` with SSR, We may see this error .
    //     react-dom.development.js:70 Warning: Did not expect server HTML to contain the text node "
    // - 1. It's ok for production env,
    // - 2. Will go away if we remove: prerender: true or console.log
    // see https://github.com/facebook/react/issues/10879
  }


  componentDidMount() {
    console.log ("Living text componentDidMount this.props", this.props)
    this.setState({
      placeholder: this.props.placeholder,
      raw: this.props.raw,
      formed: this.form(this.props.raw)
    })
  }

  componentDidUpdate(prevProps){
    if(this.props.raw != prevProps.raw){
      console.log ("componentDidUpdatethis.props", this.props)
      this.setState({
        placeholder: this.props.placeholder,
        raw: this.props.raw,
        formed: this.form(this.props.raw),
      })
    }
  }


  handleChange = (event) => {
    // setState here to aoivd jumping cursor problem
    // https://stackoverflow.com/a/28922465/2566679
    // https://github.com/facebook/react/issues/955
    this.setState({
      raw: event.target.value
    })

    this.props.handleChange(event)
  }

  form(raw){
    if (!raw) {
     return '';
    }

    const formed = this.state.potter.form(raw)
    // living = this.state.breathe(formed)   // do with dynamic_data_source
    return formed;
  }

  render () {
    return (
      <React.Fragment>
        <div className="living-text">
          <div className="raw">
            <textarea
              value={ this.state.raw }
              onChange={ this.handleChange }
              className="form-control"
              placeholder={ this.state.placeholder }
              cols={5}
            />
          </div>
          <div className="formed">
            <div
              className="living-text"
              dangerouslySetInnerHTML={{ __html: this.state.formed }}>
              {/* Don't use innerHTML.
                instead, use dangerouslySetInnerHTML
                https://stackoverflow.com/a/37339542/2566679
              */}
            </div>
          </div>
          <div className="clearfix" >
          </div>
        </div>
      </React.Fragment>
    );
  }
}

LivingText.propTypes = {
  greeting: PropTypes.string
};
export default LivingText
