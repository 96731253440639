import React from 'react';
import {createStore, applyMiddleware, compose} from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './daat/reducers';
import sagas from './daat/sagas';
import Article from './daat/Article';


const Daat = (props) => {

  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    reducers,
    props,  // init state from server,
    applyMiddleware(sagaMiddleware)
  )
  sagaMiddleware.run(sagas);

  return (
    <Provider store={ store }>
      <div className="daat">
        <Article />
      </div>

    </Provider>
  )
}

export default Daat;
