import React from "react"
import PropTypes from "prop-types"

// import Api from '../../src/api'
import Article from "./model/Article"


class TruthEditor extends React.Component {
  constructor(props) {
    super(props);
    console.log("TruthEditor constructor p", this.props)
    // opts = {
    //   dust: { abc: true },
    //   breath_of_life: {
    //     truth: { 1: 10, 2: 5, 3: 2 },
    //     other: {}
    //   }
    // }
    // new Potter(opts)


    this.state = {
      // living_text: {
      //
      // }
      // raw: '',
      // formed: '<h1>tmp init value TODO from db </h1>',
      // name: this.props.name,
      // potter: new Potter()
    }

    // console.log("constructor p", this.props)

    // SSR: When we use `console.log` with SSR, We may see this error .
    //     react-dom.development.js:70 Warning: Did not expect server HTML to contain the text node "
    // - 1. It's ok for production env,
    // - 2. Will go away if we remove: prerender: true or console.log
    // see https://github.com/facebook/react/issues/10879
  }

  // onSuccess(data){
  onSuccess = (data) =>  {
    console.log("success", data);
    console.log("this", this);


    const raw = data.data.debate.description;

    this.setState({
      raw: raw,
      formed: this.form(raw)
    })
  }

  // To prevent undefined this,
  // See https://www.voitanos.io/blog/deal-with-undefined-this-react-event-handler-performant-way/
  // Try to choose the one has no problem for three ways of rendering.
  // - Normal render
  // - SSR
  // - Mini Racer
  handleChange = (event) =>  {
    console.log ("handleChangeArrow event.target.value ", event.target.value)
    this.setState({
      raw: event.target.value,
      formed: this.form(event.target.value)
    })
  }

  // handleChange(event) {
  //   console.log ("handleChange event.target.value ", event.target.value)
  //   this.setState({
  //     raw: event.target.value,
  //     formed: this.form(event.target.value)
  //   })
  // }

  form(raw){
    const formed = this.state.potter.form(raw)
    // living = this.state.breathe(formed)   // do with dynamic_data_source
    return formed;
  }

  renderModel(){
    switch(this.props.model) {
      case 'Statement':
        return (
          <Statement
            id={ this.props.id }
          />
        );
      case 'Judgement':
        return (
          <Judgement
            id={ this.props.id }
            statement={ this.props.statement }
            judgement={ this.props.judgement }
          />
        );
      case 'Debate':
        return (
          <Debate
            id={ this.props.id }
            statement={ this.props.statement }
          />
        );
      case 'Article':
        return (
          <Article
            article={ this.props.article }
          />
        );
      case 'checkbox_two_depth':
        // return (
        //   <CheckBoxGroupTwoDepth
        //     key={ filter_group.id }
        //     filter_group={ filter_group }
        //     filterGroupChanged={ this.filterGroupChanged }
        //     filterModalOnBtnClicked={ this.filterModalOnBtnClicked }
        //   />
        // );
      }
  }

  render () {
    return (
      <React.Fragment>
        <div className="truth-editor-container-space">

        </div>
        <div className="truth-editor-container">
          <div className="truth-editor">
            { this.renderModel() }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TruthEditor.propTypes = {
  greeting: PropTypes.string
};
export default TruthEditor
