export default (state = {}, action) => {
  switch (action.type) {
    case 'LOADING_BAR':
      return state.map(cell => {
        if (cell.index != action.index){
          return cell
        }

        return {
          ...cell,
          meta: {
            ...cell.meta,
            loading: action.loading
          }
        }
      })

    case 'FETCH_STARTING':
      return state.map(cell => {
        if (cell.index != action.index){
          return cell
        }

        return {
          ...cell,
          meta: {
            ...cell.meta,
            force_fetch: false
          }
        }
      })

    case 'REFRESH_ALL_CELLS':
      return state.map(cell => {
        return {
          ...cell,
          meta: {
            ...cell.meta,
            force_fetch: true
          }
        }
      })

    case 'SET_CELL_DATA':
      return state.map(cell => {
        if (cell.index != action.index){
          return cell
        }

        return {
          ...cell,
          data: action.data
        }
      })

    case 'CHANGE_CELL_URL':
      return state.map(cell => {
        if (cell.index != action.index){
          return cell
        }

        return {
          ...cell,
          meta: {
            ...cell.meta,
            data_fetch_url: action.url
          }
        }
      })

    case 'SET_CELLS':
      return action.cells

    default:
      return state;
  }
}
