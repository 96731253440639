import React from "react"
import PropTypes from "prop-types"

class Action extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render () {
    return (

      <div className="form-actions-container">
        <div className="form-actions">
        { this.props.persisted ?
          <button className="btn btn-sm btn-outline-danger btn-debate-edit-cancel">취소</button> :
          null
        }
        <button
          className="btn btn-sm btn-outline-success btn-debate-sumit"
          onClick={ (e) => this.props.handleSubmit(e) }
          >
          저장
        </button>
        </div>
      </div>
    );
  }
}

Action.propTypes = {
  greeting: PropTypes.string
};
export default Action
