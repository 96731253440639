export const fetchCellData = (opts) => {
  return {
    type: "FETCH_CELL_DATA",
    ...opts
  }
}

export const setCellData = (opts) => {
  return {
    type: "SET_CELL_DATA",
    ...opts
  }
}

export const setCells = (opts) => {
  return {
    type: "SET_CELLS",
    ...opts
  }
}

export const loadingBar = (opts) => {
  return {
    type: "LOADING_BAR",
    ...opts
  }
}

export const setSelectedPeriod = (opts) => {
  return {
    type: "SET_SELECTED_PERIOD",
    ...opts
  }
}

export const changeCellUrl = (opts) => {
  return {
    type: "CHANGE_CELL_URL",
    ...opts
  }
}

export const fetchStarting = (opts) => {
  return {
    type: "FETCH_STARTING",
    ...opts
  }
}

export const refreshAllCells = () => {
  return {
    type: "REFRESH_ALL_CELLS"
  }
}
